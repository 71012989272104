import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./blog-post.css"
export default function Template({
  data, // this prop will be injected by the GraphQL query we'll write in a bit
}) {
  const { markdownRemark: post } = data // data.markdownRemark holds your post data
  return (
    <Layout>
      <SEO title={`${post.frontmatter.title}`} />
      <div className="blog-post-container card">
        <div className="blog-post">
          <h1 className="post-title">{post.frontmatter.title}</h1>
          <div className="secondary-text">{post.frontmatter.date}</div>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`

// https://www.gatsbyjs.org/blog/2017-07-19-creating-a-blog-with-gatsby/
